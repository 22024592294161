<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>       
        <v-container xl fluid v-else>
            <v-row>
                 <v-overlay :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1">
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60">
                            </v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12" class="center">
                    <div class="margen d-flex">
                        <hr class="vr"/>
                        <p class="tblName">{{nombre_tipo_reporte}}</p>
                    </div>
                    <v-card class="borde-card big elevation-0">
                        <v-card-text class="pa-5" >
                            <ValidationObserver ref="form" v-slot="{ invalid, validated  }">
                                <v-row>
                                    <v-col cols="6" v-if="!reporte_fijo">
                                        <v-menu button rounded offset-y class="elevation-0 d-inline ml-2 mr-2 "> 
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn
                                                    color="#EFEFEF" 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    right
                                                    rounded
                                                    class="btnReportes justify-space-between elevation-0 col-lg-8 "
                                                >
                                                {{reporte_dinamico_nombre}}
                                                <v-icon class="">keyboard_arrow_down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item 
                                                    v-for="(item, index) in reportesDinamicos"
                                                    :key="index"
                                                    @click="changeReporteDinamicoItem(item)"
                                                >   
                                                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                    <v-col :cols="reporte_fijo?12:6"  class="d-flex justify-end align-center text-center">                                        
                                        
                                        <div class="text-exportar">
                                            Exportar en 
                                        </div>
                                        <v-btn
                                            color="#FFFFFF" 
                                            @click="generarReporte('xlsx')"
                                            rught
                                            rounded
                                            class="btnReportes elevation-0   mr-1"
                                        >
                                            <img src="/static/icon/xls.png" style="width:28px" />
                                        </v-btn>
                                        <v-btn
                                            rounded
                                            class="btnReportes btnBorrar elevation-0  "
                                            @click="resetValues()"
                                        >
                                            Borrar campos
                                        </v-btn>
                                    </v-col> 
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="2" md="2" class=" pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Clientes"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" :sm="rol == 'root'?2:4" :md="rol == 'root'?2:4" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="registrosPatronales"
                                            item_text="nombreClave"
                                            item_value="id"
                                            @dataMultiple="(data)=> registrosPatronalesSeleccionados = data"
                                            rules="required"
                                            label="Registros patronales"
                                            ref="multRegistroPatronal"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="empresas"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> empresasSeleccionadas = data"
                                            label="Empresas"
                                            ref="multEmpresa"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="2" md="2" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="sucursales"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> sucursalesSeleccionadas = data"
                                            label="Sucursales"
                                            ref="multSucursal"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="2" md="2" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="departamentos"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> departamentosSeleccionados = data"
                                            label="Departamentos"
                                            ref="multDepartamento"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                       
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="tipos_nominas"
                                            item_text="claveDescripcion"
                                            @dataMultiple="(data)=> tiposNominasSeleccionadas = data"
                                            label="Tipo de nómina"
                                            ref="multTiposNomina"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            label="Año fiscal"
                                            :items="items_anios"
                                            item-text="nombre"
                                            item-value="id"
                                            persistent-hint
                                            v-model="anio_id"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="meses"
                                            item_text="nombre"
                                            item_value="valor"
                                            @dataMultiple="(data)=> mesesSeleccionados = data"
                                            label="Meses"
                                            ref="multMeses"
                                        >
                                        </VuetifyMultipleAutocomplete>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class=" pb-0 ma-0">
                                        <!-- <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="nominas"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> nominasSeleccionadas = data"
                                            label="Nóminas"
                                            ref="multNominas"
                                        /> -->
                                        <v-autocomplete
                                            outlined
                                            label="Nóminas"
                                            :items="nominas"
                                            item-text="nombre"
                                            item-value="id"
                                            persistent-hint
                                            v-model="nominaSeleccionada"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>

                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="4" md="4" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="empleados"
                                            item_text="nombre_completo"
                                            @dataMultiple="(data)=> empleadosSeleccionados = data"
                                            label="Empleados"
                                            ref="multEmpleados"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'  || rol=='admin-empresa'" xs="12" sm="3" md="3" class=" radio_group_01 radio-contrato" >
                                            <template >
                                                <div class="mb-4">Estatus de los empleados:</div>
                                            </template>
                                            <v-radio-group v-model="empleadoEstatus" row>
                                                <v-radio class="radio-label" label="Todos" value="ALL"></v-radio>
                                                <v-radio class="radio-label" label="Activos" value="ACTIVO"></v-radio>
                                                <v-radio class="radio-label" label="Inactivos" value="INACTIVO"></v-radio>
                                        </v-radio-group>
                                    </v-col>

                                    <v-col v-if="(rol == 'root' || rol=='admin'  || rol=='admin-empresa') && desglosarPercepciones" xs="12" sm="3" md="3" class=" radio_group_01 radio-contrato" >
                                            <v-radio-group v-model="desglosarTipo" row>
                                                <v-radio class="radio-label" label="Con Total" value="conTotal"></v-radio>
                                                <v-radio class="radio-label" label="Sin Total" value="sinTotal"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    
                                    <v-col v-if="detallePorEmpleado" cols="12" xs="12" sm="12" md="12" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="encabezadosEmpleados"
                                            item_text="nombre"
                                            item_value="valor"
                                            :compress="false"
                                            @dataMultiple="(data)=> encabezadosEmpleadosSeleccionados = data"
                                            rules="required"
                                            label="Campos de los empleados"
                                            ref="multEmpleadosHeader"
                                        />
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

//IMPORTS

//APIS

import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import IncidenciasNominasApi from '@/api/nominas/incidencias';
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import reportesDinamicosApi from '@/api/nominas/reporteDinamico';
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import apiEmpleados from '@/api/empleados';
import apiAnios from '@/api/anual_fiscal';

//COMPONENTS

import Datatable from '@/components/datatable/Datatable';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItem from '@/components/ExpandableItemPayments.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete.vue';

//PLUGINS

import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        VuetifyMultipleAutocomplete,
        VuetifyDatePicker,
        'data-table': Datatable,
        ExpandableItem,
    },
    data() {
        return {
            reportesDinamicos       : [],
            nombre_tipo_reporte     : "general",
            nombre_reporte          : "",
            urlReporte              : null,
            accion                  : "create",
            datosLogin              : null,
            rol                     : null,
            cliente_value           : null,
            totalizar_value         : null,
            registroPatronal_value  : null,
            tipo_nomina_id          : null,

            reporte_fijo            : false,
            dialog_pdf              : false,
            dialogSeleccionTipoPago : false,
            dialogReporte           : false,
            detallePorEmpleado      : false,
            consolidadoEmpleado     : false,
            desglosarPercepciones   : false,
            detallePorNomina        : false,
            percepcionesBool        : false,
            deduccionesBool         : false,
            otrosPagosBool          : false,
            procesoFinalizado       : false,
            isSaving                : false,
            overlay                 : false,
            masivo                  : false,
            checkAll                : false,
            determinado             : false,

            reporte_dinamico_nombre: "Seleccionar tipo de reporte",
            titulo_modal    : "",
            url             : "contratos/find",
            imagen          : "/static/modal/contrato.png",
            fraseOverlay    : "Descargando",
            empleadoEstatus : "ALL",
            desglosarTipo   : "sinTotal",

            clientes                            : [],
            registrosPatronales                 : [],
            empresas                            : [],
            sucursales                          : [],
            departamentos                       : [],
            tipos_nominas                       : [],
            items_anios                         : [],
            nominas                             : [],
            empleados                           : [],
            complementos                        : [],
            estatusComplementos                 : [],
            encabezadosEmpleados                : [{nombre:"Clave",valor:"Clave-clave"},
                                                   {nombre:"Clave interna",valor:"Clave interna-clave_interna"},
                                                   {nombre:"Nombre",valor:"Nombre-nombre"},
                                                   {nombre:"Apellido paterno",valor:"Apellido paterno-apellido_paterno"},
                                                   {nombre:"Apellido materno",valor:"Apellido materno-apellido_materno"},
                                                   {nombre:"Nombre completo",valor:"Nombre completo-nombre_completo"},
                                                   {nombre:"Nacionalidad",valor:"Nacionalidad-nacionalidad"},
                                                   {nombre:"Fecha de nacimiento",valor:"Fecha de nacimiento-fecha_nacimiento"},
                                                   {nombre:"Lugar de nacimiento",valor:"Lugar de nacimiento-lugar_nacimiento"},
                                                   {nombre:"Sexo",valor:"Sexo-sexo"},
                                                   {nombre:"Estado civil",valor:"Estado civil-estado_civil_id"},
                                                   {nombre:"¿Tiene hijos?",valor:"¿Tiene hijos?-tiene_hijos"},
                                                   {nombre:"Código postal",valor:"Código postal-cp_id"},
                                                   {nombre:"Estado",valor:"Estado-estado_id"},
                                                   {nombre:"Municipio",valor:"Municipio-municipio_id"},
                                                   {nombre:"Dirección",valor:"Dirección-direccion"},
                                                   {nombre:"Colonia",valor:"Colonia-colonia_id"},
                                                   {nombre:"Teléfono",valor:"Teléfono-telefono"},
                                                   {nombre:"Célular",valor:"Célular-celular"},
                                                   {nombre:"Correo electrónico",valor:"Correo electrónico-correo"},
                                                   {nombre:"Nivel de estudios",valor:"Nivel de estudios-nivel_estudios_id"},
                                                   {nombre:"Conocimientos extra",valor:"Conocimientos extra-conocimientos_extras"},
                                                   {nombre:"Dirección laboral",valor:"Dirección laboral-direccion_laboral"},
                                                   {nombre:"No. IMSS",valor:"No. IMSS-imss"},
                                                   {nombre:"RFC",valor:"RFC-rfc"},
                                                   {nombre:"CURP",valor:"CURP-curp"},
                                                   {nombre:"Tipo de sangre",valor:"Tipo de sangre-tipo_sangre"},
                                                   {nombre:"Observaciones de salud",valor:"Observaciones de salud-observacion_salud"},
                                                   {nombre:"Cliente",valor:"Cliente-cliente_id"},
                                                   {nombre:"Empresa",valor:"Empresa-empresa_id"},
                                                   {nombre:"Sucursal",valor:"Sucursal-sucursal_id"},
                                                   {nombre:"Departamento",valor:"Departamento-departamento_id"},
                                                   {nombre:"Horario",valor:"Horario-horario_id"},                                                 
                                                   {nombre:"Puesto",valor:"Puesto-puesto_id"},
                                                   {nombre:"Tipo del empleado",valor:"Tipo-empleado_tipo_id"},
                                                   {nombre:"Días de vacaciones",valor:"Días de vacaciones-dias_vacaciones"},
                                                   {nombre:"Día de descanso",valor:"Día de descanso-dia_descanso"},
                                                   {nombre:"Entidad CFDI",valor:"Entidad CFDI-entidad_cfdi"},
                                                   {nombre:"Registro patronal",valor:"Registro patronal-registro_patronal_id"},
                                                   {nombre:"Fecha de ingreso",valor:"Fecha de ingreso-fecha_ingreso"},
                                                   {nombre:"Fecha de alta IMSS",valor:"Fecha de alta IMSS-fecha_alta_imss"},
                                                   {nombre:"Fecha de planta",valor:"Fecha de planta-fecha_planta"},
                                                   {nombre:"Fecha de reingreso",valor:"Fecha de reingreso-fecha_reingreso"},
                                                   {nombre:"Fecha de baja",valor:"Fecha de baja-fecha_baja"},
                                                   {nombre:"Fecha de cobro del finiquito",valor:"Fecha de cobro del finiquito-fecha_cobro_finiquito"},
                                                   {nombre:"Contrato",valor:"Contrato-tipo_contrato_id"},
                                                   {nombre:"Tipo de nómina",valor:"Tipo de nómina-tipo_nomina_id"},
                                                   {nombre:"Régimen fiscal",valor:"Régimen fiscal-tipo_regimen_sat_id"},
                                                   {nombre:"Prestaciones",valor:"Prestaciones-prestacion_id"},
                                                   {nombre:"Salario diario",valor:"Salario diario-salario_diario"},
                                                   {nombre:"Salario diario fiscal",valor:"Salario diario fiscal-salario_diario_fiscal"},
                                                   {nombre:"Salario base cotización",valor:"Salario base cotización-salario_base_cotizacion"},
                                                   {nombre:"Tipo de pago",valor:"Tipo de pago-tipo_pago"},
                                                   {nombre:"Cuenta INFONAVIT",valor:"Cuenta INFONAVIT-cuenta_infonavit"},
                                                   {nombre:"Tipo de descuento INFONAVIT",valor:"Tipo de descuento INFONAVIT-tipo_factor"},
                                                   {nombre:"Descuento INFONAVIT",valor:"Descuento INFONAVIT-factor_descuento"},
                                                   {nombre:"Fecha inicio del descuento INFONAVIT",valor:"Fecha inicio del descuento INFONAVIT-fecha_inicio_descuento"},
                                                   {nombre:"No. AFORE",valor:"No. AFORE-afore"},
                                                   {nombre:"Cuenta FONACOT",valor:"Cuenta FONACOT-cuenta_fonacot"},
                                                   {nombre:"Brigada",valor:"Brigada-brigada_proteccion_civil_id"},
                                                   {nombre:"Observaciones generales",valor:"Observaciones generales-observaciones"}
                                                   ],  
            encabezadosNominas                  : [{nombre:"Días de pago",valor:"Días de pago-dias_de_pago"},
                                                   {nombre:"Sueldo",valor:"Sueldo-sueldo-p_Sueldo"},
                                                   {nombre:"Subsidio para el empleo",valor:"Subsidio para el empleo-subsidio_entregar-o_SubsidioEntregar"},
                                                   {nombre:"Subsidio pagado",valor:"Subsidio pagado-subsidio_pagado_entregar"},
                                                   {nombre:"Subsidio causado",valor:"Subsidio causado-subsidio_causado-o_SubsidioCausado"},
                                                   {nombre:"Ajuste de subsidio causado",valor:"Ajuste de subsidio causado-ajuste_subsidio_causado-d_AjusteSubsidioCausado"},
                                                   {nombre:"Ajuste de subsidio pagado",valor:"Ajuste de subsidio pagado-ajuste_subsidio_pagado-d_AjusteSubsidioPagado"},
                                                   {nombre:"Total de otros ingresos",valor:"Total de otros ingresos-total_otros_ingresos"},
                                                   {nombre:"Percepciones exentas",valor:"Percepciones exentas-percepciones_excentas"},
                                                   {nombre:"Percepciones gravadas",valor:"Percepciones gravadas-percepciones_grabadas"},
                                                   {nombre:"Total de percepciones",valor:"Total de percepciones-total_percepciones"},
                                                   {nombre:"ISR",valor:"ISR-ISR-d_IsrRetener"},
                                                   {nombre:"ISR subsidio ajustado",valor:"ISR subsidio ajustado-ISR_ajustado_subsidio-o_IsrAjustadoSubsidio"},
                                                   {nombre:"Ajuste ISR",valor:"Ajuste ISR-ISR_ajuste_mensual"},
                                                   {nombre:"IMSS",valor:"IMSS-IMSS-d_Imss"},
                                                   {nombre:"Total de deducciones",valor:"Total de deducciones-total_deducciones"},
                                                   {nombre:"Neto a pagar",valor:"Neto a pagar-neto_pagar"},
                                                   {nombre:"Faltas",valor:"Faltas-faltas"},
                                                   {nombre:"Incapacidades",valor:"Incapacidades-incapacidades"},
                                                   {nombre:"Vacaciones",valor:"Vacaciones-vacaciones"},
                                                   {nombre:"Total INFONAVIT",valor:"Total INFONAVIT-total_infonavit_bimestral"},
                                                   ],
            totalizar_por                       : [{nombre:"Empresa",valor:"empresa"},
                                                   {nombre:"Sucursal",valor:"sucursal"},
                                                   {nombre:"Departamento",valor:"departamento"}
                                                   ],
            registrosPatronalesSeleccionados    : [],
            empresasSeleccionadas               : [],
            sucursalesSeleccionadas             : [],
            departamentosSeleccionados          : [],
            tiposNominasSeleccionadas           : [],
            aniosSeleccionados                  : [],
            anio_id                             : null,
            nominasSeleccionadas                : [],
            nominaSeleccionada                  : null,
            empleadosSeleccionados              : [],
            complementosSeleccionados           : [],
            estatusComplementosSeleccionados    : [],
            encabezadosEmpleadosSeleccionados   : [],
            encabezadosNominasSeleccionados     : [],
            columns                 : [
                {
                    label: "Tipo / Periodo",
                    name: "tipo",
                    filterable: true,
                },
                {
                    label: "Fecha de pago",
                    name: "fecha_pago",
                    filterable: true,
                },
                {
                    label: "Tipo de pago",
                    name: "tipo_pago",
                    filterable: true,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    align: 'center',
                    filterable: false,
                },
                {
                    label: "",
                    name: "desplegar",
                    align: 'center',
                    width:"8",
                    filterable: false,
                },
            ],

            filters         : {
            },
            pagoNomina      : {
                tipo_pago : "1"
            },
            archivo         : {
                archivo                 : [],
                cliente_id              : null,
                empresa_id              : null,
                sucursal_id             : null,
                departamento_id         : null,
                empleado_id             : null,
                documento_id            : null,
                url                     : null,
                extension               : null,
                nombre                  : null,
                contrato_empleado_id    : null
            },
            reporte           : {
                id : null,
                cliente_id : null
            },
            fecha_inicio                : null,
            fecha_fin                   : null,
            mesesSeleccionados:[],
            meses: [
                {
                    nombre:"Enero",
                    valor: "Enero"
                },
                {
                    nombre:"Febrero",
                    valor: "Febrero"
                },
                {
                    nombre:"Marzo",
                    valor: "Marzo"
                },
                {
                    nombre:"Abril",
                    valor: "Abril"
                },
                {
                    nombre:"Mayo",
                    valor: "Mayo"
                },
                {
                    nombre:"Junio",
                    valor: "Junio"
                },
                {
                    nombre:"Julio",
                    valor: "Julio"
                },
                {
                    nombre:"Agosto",
                    valor: "Agosto"
                },
                {
                    nombre:"Septiembre",
                    valor: "Septiembre"
                },
                {
                    nombre:"Octubre",
                    valor: "Octubre"
                },
                {
                    nombre:"Noviembre",
                    valor: "Noviembre"
                },
                {
                    nombre:"Diciembre",
                    valor: "Diciembre"
                },
            ]
        }
    },

    computed:{
        anioFiscalComputed(){
            let objectAnio = this.items_anios.find(element => element.id == this.anio_id)
            if(objectAnio){
                return objectAnio.nombre
            }
            else{
                return
            }   
        }
    },
    /* WATCH 
    Entrada: valor nuevo de la variable y valor pasado de la variable (newValue,oldValue)
    Salida:
    Descripcion: Las funciones de este tipo reciben el valor 
                 nuevo y pasado de una variable cuando detecta 
                 un cambio para posteriormente ejecutar alguna 
                 instruccion.*/
    watch: {
        /*Descripcion: Ejectuta cambiaCliente() mandando
                       el nuevo valor de esta variable*/
        cliente_value: function(val) {
            this.cambiaCliente(val);
            this.getReportesDinamicos();
        },
        /*Descripcion: Ejectuta getcliente() */
        registroPatronal_value: function(val) {
            this.getEmpleados();
        },
        registrosPatronalesSeleccionados:{
            deep:true,  
            handler(val){
                this.getEmpleados();
                this.cambiaRegistroPatronal();
            }
        },
        /*Descripcion: Ejectuta cambiaEmpresa() mandando
                       el nuevo valor de esta variable*/
        empresasSeleccionadas:{
            deep:true,  
            handler(val){
                this.cambiaEmpresas(val);
                this.getTiposNominas();
                this.getAnios();
            }
        },
        /*Descripcion: Ejectuta cambiaScursal() mandando
                       el nuevo valor de esta variable*/
        sucursalesSeleccionadas:{
            deep:true,  
            handler(val){this.cambiaSucursales(val);}
        },

        /*Descripcion: Ejectuta getEmpleados() */
        departamentosSeleccionados:{
            deep:true,  
            handler(val){this.getEmpleados();}
        },
        
        /*Descripcion: Ejectuta cambiaTipoNominas() */
        tiposNominasSeleccionadas:{
            deep:true,  
            handler(val){

                if(this.anio_id){
                    this.cambiaTipoNominas(val);
                }
                
            }
        },
        mesesSeleccionados:{
            deep: true,
            handler(val) {
                if(this.anio_id){
                    this.cambiaTipoNominas(this.tiposNominasSeleccionadas);
                }
            }
        },
        anio_id: function(val, oldVal){
            if(val){
                if(this.tiposNominasSeleccionadas.length>0){
                    this.cambiaTipoNominas(this.tiposNominasSeleccionadas)
                }
            }
        },

        empleadoEstatus: function(val){
            if(this.cliente_value != null)
                this.getEmpleados();
        },
        percepcionesBool:function(val){
            this.changeComplemento();
        },
        deduccionesBool:function(val){
            this.changeComplemento();
        },
        otrosPagosBool:function(val){
            this.changeComplemento();
        }
    },
    methods: {
        /**
         * @method getEmpleados Este método realiza la petición a la API de empleados
         * @description Realiza petición a la API de empleados cuando la empresa es seleccionada y el resultado lo almacena en el array empleados[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getEmpleados(){
            let parametros = { 
                paginate: false,
                usuario_id : this.datosLogin.id,
                cliente_id: this.cliente_value, 
                rol_name: this.datosLogin.rol.name,
                arrayRegistroPatronal : this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados), 
                arrayEmpresa : this.empresasSeleccionadas.length == 0 ? null:JSON.stringify(this.empresasSeleccionadas), 
                arraySucursal : this.sucursalesSeleccionadas.length == 0 ? null:JSON.stringify(this.sucursalesSeleccionadas), 
                arrayDepartamento : this.departamentosSeleccionados.length == 0 ? null:JSON.stringify(this.departamentosSeleccionados)
            };

            if(this.empleadoEstatus == "ALL"){
                parametros.all = true;
            }else if(this.empleadoEstatus == "ACTIVO"){
                parametros.activo = true;
                parametros.baja   = false;
            }else if(this.empleadoEstatus == "INACTIVO"){
                parametros.activo = false;
                parametros.baja   = true;
            }

            apiEmpleados.getEmpleados(parametros).then((response)=>{
                this.empleados = response.data;
                this.empleados.map(emp => emp.nombre_completo += " - "+emp.nombre_empresa + " - "+emp.nombre_sucursal + " - "+emp.nombre_departamento);
            })
            .catch(error => {
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },

        /**
         * @method init Este método se ejecuta para inicializar los datos al crear la vista.
         * @description Este método se ejecuta en el ciclo de vida created(), se rellenan valores antes de montar la vista.
        */
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            this.urlImg = GetUrlImg.getURL();
            
            this.nombre_tipo_reporte = "Desglose de IMSS"
            this.reporte_fijo = true;

            if (this.rol == "root") {
                this.getClientes();
                if(this.reporte_fijo)
                    this.getReportesDinamicos();
                else
                    this.getReportesApi('imss');
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresasSeleccionadas.push(this.datosLogin.empresa_id);
            }
            else {
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
            }

        },
        
        /**
         * @method getTiposNominas Este método realiza la petición a la API de tipos de nómina.
         * @description Este método se ejecuta al seleccionar al menos una empresa. El resultado se almacena en el array tipos_nominas=[]
        */
        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                arrayEmpresa: JSON.stringify(this.empresasSeleccionadas)
            }
            this.tipos_nominas = [];
            await apiTipoNomina.find(param).then((response) => {
                this.tipos_nominas = response.data;
                this.tipos_nominas.map(tipo_nomina => tipo_nomina.claveDescripcion = tipo_nomina.clave + ' - ' + tipo_nomina.descripcion+ " - "+tipo_nomina.nombre_empresa);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },

        /**
         * @method getAnios Este método realiza la petición a la API de años.
         * @description Este método se ejecuta al seleccionar al menos una empresa. El resultado se almacena en el array items_anios=[]
        */
        async getAnios() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                arrayEmpresa: JSON.stringify(this.empresasSeleccionadas)
            }
            this.items_anios = [];
            await apiAnios.empresasAnios(param).then((response) => {
                this.items_anios = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },

        /**
         * @method getClientes Este método realiza la petición a la API de clientes.
         * @description Este método se ejecuta al seleccionar al iniciar la vista. El resultado se almacena en el array clientes=[]
        */
        async getClientes() {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        /**
         * @method getReportesDinamicos Este método realiza la petición a la API de reportes.
         * @description Este método se almacena en el array reportesDinamicos=[]
        */
        async getReportesDinamicos() {
            let param = {};
            //Mis reportes let param = {all:false,cliente_id:this.cliente_id}; Falta vista

            await reportesDinamicosApi.getReportes(param).then(response => {
                this.reportesDinamicos = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los reportes");
            });
        },

        /**
         * @method getReportesApi Este método realiza la petición a la API de reportes.
         * @description Este método se almacena en el array reportesDinamicos=[]
        */
        async getReportesApi(tipo) {
            let param = {};

            await reportesDinamicosApi.getReportes(param).then(response => {
                this.reportesDinamicos = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los reportes");
            });
        },

        /**
         * @method cambiaCliente Este método realiza la petición a la API de Registros patronales.
         * @description Este método se almacena en el array registrosPatronales=[]. Se ejecuta al seleccionar un cliente.
        */
        async cambiaCliente(cliente) {
           if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            await apiRegistroPatronal.find(param).then(response => {
                //this.registrosPatronales = response.data;
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
            
            this.changeComplemento();
        },

        /**
         * @method cambiaRegistroPatronal Este método realiza la petición a la API de Empresas.
         * @description Este método se almacena en el array empresas=[]. Se ejecuta al seleccionar al menos una empresa.
        */
        async cambiaRegistroPatronal(){
            this.registroPatronal_value = null;
            let param = {   activo: true, 
                            paginate: false, 
                            cliente_id: this.cliente_value,
                            arrayRegistroPatronal: this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados)
            };

            if(this.registrosPatronalesSeleccionados.length == 0){
                this.empresas = []
                return
            }
            await empresaApi.getEmpresas(param).then(response => {
                    this.empresas = response.data;
                    this.getEmpleados();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        async getComplementos() {
            let params = {
                cliente_id : this.cliente_value
            };

            await IncidenciasNominasApi.getTiposIncidencias(params).then(response => {
                this.complementos = response.data;
                this.complementos.map(com => com.nombre = com.codigo+" - "+com.nombre);
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los complementos de nómina.");
            });
        },

        async changeComplemento(){
            if(this.cliente_value != null){
                await this.getComplementos();
                if(this.percepcionesBool || this.deduccionesBool || this.otrosPagosBool){
                    
                    let percepciones = [];
                    let deducciones = [];
                    let otrosPagos = [];

                    for(let item of this.complementos){
                        switch (item.codigo.charAt(0)) {
                            case 'P':
                                percepciones.push(item);
                                break;
                            case 'D':
                                deducciones.push(item);
                                break;
                            case 'O':
                                otrosPagos.push(item);
                                break;    
                            default:
                                break;
                        }
                    }

                    this.complementos = [];
                    this.complementosSeleccionados = [];
                    if(this.percepcionesBool){
                        this.complementos = [...this.complementos,...percepciones];
                    }
                    if(this.deduccionesBool){
                        this.complementos = [...this.complementos,...deducciones];
                    }
                    if(this.otrosPagosBool){
                        this.complementos = [...this.complementos,...otrosPagos];
                    }
                }
            }
        },

        async cambiaEmpresas(items) {
            if(items.length == 0){
                this.sucursales = [];
                return;
            }
            let parametros = { activo: true, paginate: false, arrayEmpresa: JSON.stringify(items)};
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
                this.sucursales.map(suc => suc.nombre += " - "+suc.nombre_empresa);
                this.getEmpleados();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },


        cambiaSucursales(items) {
            if(items.length == 0){
                this.departamentos = [];
                return;
            }
            
            let parametros = { activo: true, paginate: false, arraySucursal: JSON.stringify(items) };
            departamentoApi.getDepartamentos(parametros).then((response)=>{
                this.departamentos = response.data;
                this.departamentos.map(depto => depto.nombre += " - "+depto.nombre_sucursal);
                this.getEmpleados();
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error);
            });
        },

        cambiaTipoNominas(items=[]) {
            
            this.nominas = [];
            if(items.length == 0){
                return;
            }
            
            let param = {
                cliente_id      : this.cliente_value,
                acumulado       : true,
                paginate        : false,
                arrayTipoNomina : JSON.stringify(items),
                anioFiscal_id   : this.anio_id,
                mesAcumulacion  : this.mesesSeleccionados.length!=0 ? JSON.stringify(this.mesesSeleccionados): null
            }
            apiCalculoNomina.find(param).then((response) => {
                this.nominas = response.data;
                this.nominas.map(nomina => nomina.nombre = nomina.clave_tipo_nomina + ' - ' + nomina.descripcion+ " - Periodo "+nomina.numero_periodo);
            
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron encontrar las nóminas.");
            })
        },


        resetValues() {

            this.$nextTick(() => { 

                if (this.rol == "root") {
                    this.cliente_value           = null;
                    if(this.$refs.multRegistroPatronal !== undefined)
                        this.$refs.multRegistroPatronal.setData([]);
                    if(this.$refs.multEmpresa !== undefined)
                        this.$refs.multEmpresa.setData([]);
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                }
    
                if (this.rol == "admin") {
                    if(this.$refs.multRegistroPatronal !== undefined)
                        this.$refs.multRegistroPatronal.setData([]);
                    if(this.$refs.multEmpresa !== undefined)
                        this.$refs.multEmpresa.setData([]);
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                }

                if (this.rol == "admin-empresa") {
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                } 

                this.reporte_dinamico_nombre = "Seleccionar tipo de reporte";

                if(this.$refs.multDepartamento !== undefined)
                        this.$refs.multDepartamento.setData([]);
                if(this.$refs.multTiposNomina !== undefined)
                    this.$refs.multTiposNomina.setData([]);
                this.anio_id = null;
                if(this.$refs.multNominas !== undefined)
                    this.$refs.multNominas.setData([]);
                if(this.$refs.multEmpleado !== undefined)
                    this.$refs.multEmpleado.setData([]);
                if(this.$refs.multNominasHeader !== undefined)
                    this.$refs.multNominasHeader.setData([]);
                if(this.$refs.multEmpleadosHeader !== undefined)
                    this.$refs.multEmpleadosHeader.setData([]);
                if(this.$refs.multComplemento !== undefined)
                    this.$refs.multComplemento.setData([]);

                if(this.$refs.multMeses !== undefined){
                    this.$refs.multMeses.setData([]);
                }


                this.accion             = "create";
                this.reporte.id         = null;
                this.reporte.cliente_id = null;

                this.detallePorEmpleado = false;
                this.totalizar_value    = null;
                this.consolidadoEmpleado = false;
                this.empleadoEstatus    = "ALL";
                this.percepcionesBool   = false;
                this.deduccionesBool    = false;
                this.otrosPagosBool     = false;
                this.detallePorNomina   = false;
                this.fecha_inicio       = null;
                this.fecha_fin          = null;
                this.nominaSeleccionada = null;
                if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                    this.$refs.FechaInicio.resetDate();
                }
                if(this.$refs.FechaFin != undefined || this.$refs.FechaFin != null){
                    this.$refs.FechaFin.resetDate();
                }
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },

        changeReporteDinamicoItem(data){
            this.accion = "update";
            this.reporte.id                 = data.id;
            this.reporte.cliente_id         = data.cliente_id;
            this.reporte_dinamico_nombre    = data.nombre;
            this.nombre_reporte             = data.nombre;
            this.urlReporte                 = data.url;
             
            this.$nextTick(()=>{
                if(this.$refs.multNominasHeader !== undefined)
                    this.$refs.multNominasHeader.setData(JSON.parse(data.campos_nominas));
                if(this.$refs.multEmpleadosHeader !== undefined)
                    this.$refs.multEmpleadosHeader.setData(JSON.parse(data.campos_empleados));
                //setTimeout(()=>{this.$refs.multComplemento.setData(JSON.parse(data.complementos))},1000);
            });

        },

        async generarReporte(type) {
            this.$refs.form.validate().then(success => {
				if(!success) {
                    return;
                }
				if (success) {
                    let url;
                    if(!this.reporte_fijo){
                        if(!this.urlReporte){
                            return
                        }
                        url =this.urlReporte //"reportes-dinamicos/generar";
                    }else{
                        url = "reportes/desglose-imss";
                        this.consolidadoEmpleado = true;
                        this.totalizar_value = "cliente";
                    }
					this.overlay = true;
                    let self = this;
                    const FileDownload = require("js-file-download");
                    var axios = require('axios');
                    var today = new Date();
                    let parametros = {nombre                : "Reporte",
                                    tipo                    : type,
                                    cliente_id              : this.cliente_value,
                                    arrayRegistroPatronal   : this.registrosPatronalesSeleccionados,
                                    arrayEmpresa            : this.empresasSeleccionadas,
                                    arraySucursal           : this.sucursalesSeleccionadas,
                                    arrayDepartamento       : this.departamentosSeleccionados,
                                    arrayEmpleado           : this.empleadosSeleccionados,
                                    arrayTipoNomina         : this.tiposNominasSeleccionadas,
                                    /* arrayNomina             : this.nominasSeleccionadas, */
                                    nomina_id               : this.nominaSeleccionada,
                                    detalle_empleado        : this.detallePorEmpleado,
                                    consolidado_empleado    : this.consolidadoEmpleado,
                                    desglosar_percepciones  : this.desglosarPercepciones,
                                    desglosar_tipo          : this.desglosarTipo,
                                    arrayEncabezado         : this.encabezadosEmpleadosSeleccionados,
                                    arrayEncabezadoNomina   : this.encabezadosNominasSeleccionados,
                                    arrayComplemento        : this.complementosSeleccionados,
                                    percepciones            : this.percepcionesBool,
                                    deducciones             : this.deduccionesBool,
                                    otros_pagos             : this.otrosPagosBool,
                                    totalizar               : this.totalizar_value,
                    }

                    if(this.empleadoEstatus == "ALL"){
                        parametros.all = true;
                    }else if(this.empleadoEstatus == "ACTIVO"){
                        parametros.activo = true;
                        parametros.baja   = false;
                    }else if(this.empleadoEstatus == "INACTIVO"){
                        parametros.activo = false;
                        parametros.baja   = true;
                    }

                    var nombre =  "Reporte "+this.nombre_tipo_reporte+" "+today.getDate() +
                                    "_" +
                                    (today.getMonth() + 1) +
                                    "_" +
                                    today.getFullYear() +
                                    "_" +
                                    today.getHours() +
                                    "," +
                                    today.getMinutes() +
                                    "," +
                                    today.getSeconds() +"." + type;

                    try {
                        axios({
                            method: "post",
                            url: url,
                            responseType: "blob",
                            data: parametros,
                        }).then(function(response) {
                            self.overlay = false;
                            if (response.status == 200) 
                                FileDownload(response.data,nombre);
                            else 
                                Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico.");
                        })
                        .catch(async (error)=>{
                            this.overlay = false;
                            let errorString = error.response.data;
                            if (
                                error.request.responseType === 'blob' &&
                                error.response.data instanceof Blob &&
                                error.response.data.type &&
                                error.response.data.type.toLowerCase().indexOf('json') != -1
                            ){
                                errorString = JSON.parse(await error.response.data.text());
                                console.log(errorString)

                                if(typeof errorString.error === 'string'){
                                    Notify.ErrorToast(errorString.error);
                                }else{
                                    let error = Object.values(errorString.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda+= `* ` + error[i] + `\n`;
                                    }
                                    Notify.ErrorToast(leyenda);
                                }
                            }; 
                        });
                    } catch (error) {
                        self.overlay = false;
                    }
				}
			});
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },

    },
    //CREATED Se ejecutan las funciones o intrucciones iniciales para el funcionamiento del modulo.
    created() {
        this.init();
    },
    
}
</script>


<style scoped>
    .radio_group_01 div{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }

    .btnReportes.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        text-transform: none;
        transition: 0.3s;
        height: 38px !important;
        background: #EFEFEF;
        border-radius: 15px;
    }

    .btnReportes.theme--light.v-btn:hover {
        background: #EFEFEF !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        }
    .margen {
        height: 48px;
        margin-bottom: 20px;
        margin-left: 1px;
    }
    .text-exportar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .btnBorrar {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        background-color: #1E2245 !important;
        border-radius: 30px !important;
        color: white !important;
        height: 40px !important;
        outline: none;
        text-transform: none !important;
        margin-left: 3%;
        transition: all .2s;
    }
</style>
